/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import "../sass/init.scss";
import { Helmet } from "react-helmet/es/Helmet";

const Layout = ({ children }) => {
  return (

    <div id="app">
      <Helmet>
        <meta name="description"
              content="Pornvrlist is virtual reality porn website list, help to choose the best virtual reality porn."/>
      </Helmet>
      {children}
    </div>

  );
};

export default Layout;
